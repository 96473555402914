import React from "react"

import Layout from "../components/layout"
import CutContainer from "../components/cutContainer"
import CustomerList from "../components/customerList"
import EmailInput from "../components/emailInput"
import { grayGradient8 } from "../services/constants"
import styles from "./customers.module.scss"
import Header from "../components/header"
import Footer from "../components/footer"
import globalStyles from "../services/globalStyles.module.scss"
import { graphql } from "gatsby";

class CustomersPage extends React.Component {
  render() {
    const { data: { customerContent, texts: { _ } }, pageContext } = this.props
    const languageCode = pageContext && pageContext.lang ? pageContext.lang : "en";

    return (
      <Layout header={<Header num={1} translations={_} headerFullYOffset={500} mobileHeaderFullYOffset={40} fixed languageCode={languageCode}/>} translations={_} languageCode={languageCode}>
        <CutContainer
          style={{
            background: grayGradient8,
            position: "absolute",
          }}
          sizeOfCut={1}
          styleClass={styles.cutContainer}
        >
        </CutContainer>
        <h1 className={styles.header}>

            {_.customers.trusted_by}{" "}
          </h1>
        <div className={styles.customerContainer}>
          <CustomerList customers={customerContent}/>
        </div>
        <div className={styles.emailInputContainer}>
          <EmailInput
            title={_.time_to_get_started}
            placeholder={_.enter_your_email}
            buttonText={_.start_free_trial}
            translations={_.contact_message}
          />
        </div>
        <Header num={2} translations={_} className={globalStyles.hideOnMobile}  headerFullYOffset={500} languageCode={languageCode}/>
        <Footer translations={_} languageCode={languageCode} />
      </Layout>
    )
  }
}

export default CustomersPage

export const query = graphql`
  query Customers($langFile: String) {
    site{
      siteMetadata{
        ip_stack_key
      }
    }
    texts: file(relativePath: {eq: $langFile}) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        all
        time_to_get_started
        enter_your_email
        start_free_trial
        customers {
          trusted_by
          all_sports
          team_sports
          ind_sports
          pts
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    },
    customerContent: allWordpressCustomers {
      edges {
        node {
          title {
            rendered
          }
          location: wpcf_country
          order: menu_order
          image: wpcf_logo
        }
      }
    },
  }
`

import React from "react"
import Img from "gatsby-image"
import styles from "./customerList.module.scss"

const CustomerList = ({ customers }) => {

  const sorted = customers.edges.sort((a, b) => {
    if(a.node.order < b.node.order){
      return -1;
    }
    if(a.node.order > b.node.order){
      return 1;
    }
    return 0;
  })

  return (
    <div className={styles.container}>
      {sorted.map((val, index) => {
        const customer = val.node;

        return (
          <div className={styles.card} key={index}>
            <div className={styles.imageContainer}>
		{ customer.image &&
		  <img style={{objectFit: 'contain'}} src={customer.image} className={styles.image} /> }
            </div>
            <div className={styles.title}>{customer.title.rendered}</div>
          </div>
        )
      })}
    </div>
  )
}

CustomerList.propTypes = {}

export default CustomerList
